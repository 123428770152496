<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-01 13:17:08
 * @ Description: Fixer's professional service request page in the user's professional service dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            cols="12"
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row>
                <!-- Back Button & Heading -->
                <v-col
                    cols="12"
                    class="os-22-sb text-truncate"
                >
                    <v-btn
                        icon
                        @click="back()"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <span class="os-22-r">{{ serviceRequest.service.name }}</span>
                </v-col>

                <v-col
                    cols="12"
                    class="py-0"
                >
                    <v-divider />
                </v-col>
                <!-- Back Button & Heading -->

                <!-- Rating & Review, Project Fee and Request Details -->
                <v-col
                    cols="6"
                    style="max-height: calc(100vh - 176px); overflow-y: auto;"
                >
                    <v-row>
                        <!-- Rate & Review -->
                        <v-col
                            v-if="showReviewSection"
                        >
                            <general-work-rating-review-component
                                :selectedItem="serviceRequest"
                                :isService="true"
                                @submitReview="submitReview"
                            />
                        </v-col>
                        <!-- Rate & Review -->

                        <!-- Proposed Project Fee -->
                        <v-col
                            cols="12"
                            class="pb-6"
                            v-if="showProposedFeeSection && !showReviewSection"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 96px; bottom: -18px;"
                                    @click="confirmRejectFee"
                                    v-if="canAcceptRejectFee"
                                >
                                    Reject
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    @click="confirmAcceptFee"
                                    v-if="canAcceptRejectFee"
                                >
                                    Accept
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canCompleteAndApprovePayment"
                                    @click="confirmCompleteServiceRequest"
                                >
                                    Complete & Approve Payment
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canCompleteAndRequestPayment"
                                    @click="confirmCompleteServiceRequest"
                                >
                                    Complete & Request Payment
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-row
                                        justify="center"
                                    >
                                        <v-col
                                            cols="12"
                                            class="os-15-sb"
                                        >
                                            <span v-if="!serviceRequest.accepted_date">Project Fee Proposal</span>
                                            <span v-else>Project Fee Accepted</span>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-13-r pt-0"
                                        >
                                            <span v-if="serviceRequest.cost_description" v-html="serviceRequest.cost_description" />
                                            <span
                                                v-else
                                                class="os-12-r text-center"
                                                style="color: rgba(0, 0, 0, 0.38);"
                                            >
                                                No fee proposal description added
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="os-22-sb"
                                            cols="12"
                                        >
                                            <span v-if="serviceRequest.cost">{{ serviceRequest.currency.symbol }}{{ serviceRequest.cost }}</span>
                                            <span
                                                v-else
                                                class="os-12-r text-center"
                                                style="color: rgba(0, 0, 0, 0.38);"
                                            >
                                                No proposed fee added
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="os-15-sb"
                                            cols="12"
                                            v-if="serviceRequest.cost"
                                        >
                                            Payment Method: {{ serviceRequest.payment_option.name.toUpperCase() }}
                                        </v-col>
                                        <v-col
                                            class="os-15-sb pb-6"
                                            cols="12"
                                        >
                                            Status: 
                                            <span
                                                :style="serviceRequest.status.stage_id === 1 ? 'color: #707070;' : serviceRequest.status.stage_id === 2 ? 'color: #FFA858;' : serviceRequest.status.stage_id === 3 ? 'color: #08D500;' : serviceRequest.status.id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                                            >
                                                {{ serviceRequest.status.name }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Proposed Project Fee -->

                        <!-- Editable Proposed Project Fee -->
                        <v-col
                            cols="12"
                            class="pb-6"
                            v-if="showEditProposedFeeSection"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <!-- <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 104px; bottom: -18px;"
                                >
                                    Cancel
                                </v-btn> -->
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canProposeFee"
                                    @click="upsertServiceRequestBudget"
                                    :disabled="!validServiceRequestBudgetForm"
                                >
                                    Propose
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-form
                                        v-model="validServiceRequestBudgetForm"
                                        ref="serviceRequestBudgetForm"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                class="os-15-sb"
                                            >
                                                Project Fee Proposal
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="os-13-r py-0"
                                            >
                                                <general-tip-tap-text-editor-component
                                                    class="pb-6"
                                                    @textChanged="costDescriptionChanged"
                                                    :placeholder="'Add description and terms here.'"
                                                    :text="serviceRequest.cost_description"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                class="pb-0 os-15-sb"
                                            >
                                                Currency<span style="color: red;">*</span>
                                                <br />
                                                <v-select
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    item-color="#2E567A"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    v-model="serviceRequest.currency"
                                                    :items="currencies"
                                                    :rules="selectRules"
                                                    @change="serviceRequestBudgetEdited = true"
                                                    :readonly="currencies.length === 1 && serviceRequest.currency.id !== null"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                class="pb-0 os-15-sb"
                                            >
                                                Project Fee<span style="color: red;">*</span>
                                                <br />
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="serviceRequest.cost"
                                                    :rules="costRules"
                                                    :prefix="serviceRequest.currency.symbol"
                                                    type="number"
                                                    @input="serviceRequestBudgetEdited = true"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Editable Proposed Project Fee -->

                        <!-- Request Edit Mode -->
                        <v-col
                            cols="12"
                            v-if="editMode"
                        >
                            <v-form
                                v-model="validServiceRequestForm"
                                ref="serviceRequestForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="os-15-sb pb-0"
                                    >
                                        Service Requirements
                                    </v-col>

                                    <!-- Requirements -->
                                    <v-col
                                        cols="12"
                                    >
                                        <general-tip-tap-text-editor-component
                                            class="pb-3"
                                            :placeholder="'Provide some information about the service that you require. Be as descriptive as possible.'"
                                            @textChanged="descriptionChanged"
                                            :text="serviceRequest.requirements"
                                        />
                                    </v-col>
                                    <!-- Requirements -->

                                    <!-- Documents -->
                                    <v-col
                                        cols="12"
                                    >
                                        <general-document-component
                                            :documents="serviceRequest.document"
                                            :documentCheckboxes="false"
                                            :canDeleteDocuments="true"
                                            :canDownloadDocuments="false"
                                            :canEditDocuments="true"
                                            :fileType="'document'"
                                            @deleteDocument="confirmDeleteDocument"
                                            @createDocument="createDocument"
                                        />
                                    </v-col>
                                    <!-- Documents -->

                                    <!-- Date Requirements -->
                                    <v-col
                                        class="os-15-sb pb-0"
                                        cols="12"
                                    >
                                        Do you require this by a specific date?
                                    </v-col>
                                    <v-col
                                        md="12"
                                        lg="9"
                                    >
                                        <v-row
                                            align="center"
                                        >
                                            <v-col
                                                class="pr-1 flex-shrink-0 flex-grow-1"
                                            >
                                                <v-radio-group
                                                    row
                                                    v-model="serviceRequest.is_specific_date"
                                                    hide-details
                                                    style="margin-top: 0px;"
                                                    @change="specificDateChanged"
                                                >
                                                    <v-radio
                                                        label="Yes"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Yes"
                                                    />
                                                    <v-radio
                                                        label="No"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="No"
                                                    />
                                                    <v-radio
                                                        label="Unknown"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Unknown"
                                                    />
                                                </v-radio-group>
                                            </v-col>
                                            <v-col
                                                class="pl-0 flex-shrink-1 flex-grow-0"
                                            >
                                                <v-menu
                                                    v-model="dateMenu"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            color="#2E567A"
                                                            class="pt-2 os-13-r"
                                                            background-color="white"
                                                            :rules="dateRules"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            v-model="serviceRequest.start_date"
                                                            style="width: 150px;"
                                                            :disabled="serviceRequest.is_specific_date !== 'Yes'"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        no-title
                                                        scrollable
                                                        color="#2E567A"
                                                        @input="dateMenu = false"
                                                        v-model="serviceRequest.start_date"
                                                    />
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- Date Requirements -->

                                    <!-- E-mail Notifications -->
                                    <!-- <v-col
                                        md="12"
                                        lg="9"
                                        class="os-12-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Your request will be sent to the fixer and they will be notified of via the messenger. Do you want to be <span class="os-12-sb">notified via email</span> when you receive a reply?
                                    </v-col> -->
                                    <v-col
                                        md="12"
                                        lg="9"
                                    >
                                        <v-row
                                            align="center"
                                        >
                                            <!-- <v-col
                                                class="pr-1 flex-shrink-0 flex-grow-1"
                                            >
                                                <v-radio-group
                                                    row
                                                    v-model="serviceRequest.receive_email"
                                                    hide-details
                                                    style="margin-top: 0px;"
                                                >
                                                    <v-radio
                                                        label="Yes, send email"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Yes"
                                                    />
                                                    <v-radio
                                                        label="No"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="No"
                                                    />
                                                </v-radio-group>
                                            </v-col> -->
                                            <v-col
                                                class="text-right"
                                                cols="12"
                                            >
                                                <v-btn
                                                    text
                                                    class="no-uppercase rounded-lg os-13-sb mr-4"
                                                    color="#2E567A"
                                                    @click="editMode = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                                    color="#FFA858"
                                                    @click="upsertServiceRequest"
                                                    :disabled="cannotRequestService"
                                                >
                                                    Save
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- E-mail Notifications -->
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Request Edit Mode -->

                        <!-- Request Details -->
                        <v-col
                            cols="12"
                            v-else
                            class="pb-6"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="editMode = true"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canEditServiceRequest"
                                >
                                    Edit Service Request
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canRejectServiceRequest"
                                    @click="confirmRejectServiceRequest"
                                >
                                    Reject
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-row
                                    >
                                        <v-col
                                            class="flex-shrink-1 flex-grow-0"
                                        >
                                            <v-avatar
                                                height="46px"
                                                width="46px"
                                            >
                                                <v-img
                                                    :src="otherParty.avatar ? otherParty.avatar : otherParty.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                                    contain
                                                />
                                            </v-avatar>
                                        </v-col>
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0"
                                        >
                                            <div
                                                class="os-15-sb text-truncate"
                                            >
                                                {{ serviceRequest.service.name }}
                                            </div>
                                            <div
                                                style="color: #2E567A; opacity: 0.65;"
                                                class="os-13-r"
                                            >
                                                <div @click="goToProfile()" style="cursor: pointer; text-decoration: underline;" class="text-truncate">
                                                    <span v-if="otherParty.company_id">{{ otherParty.company_name }}</span>
                                                    <span v-else>{{ otherParty.first_name }} {{ otherParty.last_name }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-15-sb"
                                        >
                                            Service Requirements
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-13-r pt-0"
                                            v-html="serviceRequest.requirements"
                                        >
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <div
                                                class="os-15-sb pb-4"
                                            >
                                                <v-icon
                                                    color="black"
                                                >
                                                    {{ mdiCloudDownloadOutline }}
                                                </v-icon>
                                                <div style="max-width: calc(100% - 48px); display: inline;" class="ml-1 text-truncate">Download Document(s)</div>
                                            </div>
                                            <general-document-component
                                                :documents="serviceRequest.document"
                                                :documentCheckboxes="false"
                                                :canDeleteDocuments="false"
                                                :canEditDocuments="false"
                                                :fileType="'document'"
                                                @deleteDocument="confirmDeleteDocument"
                                                @downloadDocument="downloadDocument"
                                                @createDocument="createDocument"
                                                class="pt-3"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                Requested on:
                                            </div>
                                            <div
                                                style="color: #2E567A;"
                                                class="os-12-sb"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                                <span>{{ serviceRequest.created_at }}</span>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                Required by:
                                            </div>
                                            <div
                                                style="color: #2E567A;"
                                                class="os-12-sb"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                                <span v-if="serviceRequest.start_date">{{ serviceRequest.start_date }}</span>
                                                <span
                                                    class="os-12-r text-center"
                                                    style="color: rgba(0, 0, 0, 0.38);"
                                                    v-else
                                                >
                                                    No date
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Request Details -->
                    </v-row>
                </v-col>
                <!-- Rating & Review, Project Fee and Request Details -->

                <!-- Messages -->
                <v-col
                    cols="6"
                    class="pb-0"
                    style="height: calc(100vh - 174px); max-height: calc(100vh - 174px); overflow-y: auto;"
                >
                    <general-work-messaging-component
                        :selectedItem="serviceRequest"
                        :canSendMessages="canSendMessages"
                        :isService="true"
                        @resetUnreadMessageCount="resetUnreadMessageCount"
                    />
                </v-col>
                <!-- Messages -->
            </v-row>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            cols="12"
            v-else-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
        >
            <v-row>
                <!-- Heading & Back Button -->
                <v-col
                    cols="12"
                    class="os-22-sb text-center pt-6 pb-9"
                    style="position: relative;"
                >
                    <v-btn
                        icon
                        @click="back()"
                        style="position: absolute; left: 12px;"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <div class="text-truncate" style="position: absolute; left: 48px; max-width: calc(100vw - 60px);">{{ serviceRequest.service.name }}</div>
                </v-col>
                <!-- Heading & Back Button -->

                <!-- Rating & Review, Project Fee and Request Details -->
                <v-col
                    cols="12"
                >
                    <v-row>
                        <!-- Rate & Review -->
                        <v-col
                            v-if="showReviewSection"
                        >
                            <general-work-rating-review-component
                                :selectedItem="serviceRequest"
                                :isService="true"
                                @submitReview="submitReview"
                            />
                        </v-col>
                        <!-- Rate & Review -->

                        <!-- Proposed Project Fee -->
                        <v-col
                            cols="12"
                            class="pb-6"
                            v-if="showProposedFeeSection && !showReviewSection"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 96px; bottom: -18px;"
                                    @click="confirmRejectFee"
                                    v-if="canAcceptRejectFee"
                                >
                                    Reject
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    @click="confirmAcceptFee"
                                    v-if="canAcceptRejectFee"
                                >
                                    Accept
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canCompleteAndApprovePayment"
                                    @click="confirmCompleteServiceRequest"
                                >
                                    Complete & Approve Payment
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canCompleteAndRequestPayment"
                                    @click="confirmCompleteServiceRequest"
                                >
                                    Complete & Request Payment
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-row
                                        justify="center"
                                    >
                                        <v-col
                                            cols="12"
                                            class="os-15-sb"
                                        >
                                            <span v-if="serviceRequest.status.stage_id < 3">Project Fee Proposal</span>
                                            <span v-else>Project Fee Accepted</span>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-13-r pt-0"
                                        >
                                            <span v-if="serviceRequest.cost_description" v-html="serviceRequest.cost_description" />
                                            <span
                                                v-else
                                                class="os-12-r text-center"
                                                style="color: rgba(0, 0, 0, 0.38);"
                                            >
                                                No fee proposal description added
                                            </span>
                                        </v-col>
                                        <v-col
                                            class="os-22-sb"
                                            cols="12"
                                        >
                                            {{ serviceRequest.currency.symbol }}{{ serviceRequest.cost }}
                                        </v-col>
                                        <v-col
                                            class="os-15-sb pb-6"
                                            cols="12"
                                        >
                                            Payment Method: 
                                            <br v-if="$vuetify.breakpoint.xs" />
                                            {{ serviceRequest.payment_option.name.toUpperCase() }}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Proposed Project Fee -->

                        <!-- Editable Proposed Project Fee -->
                        <v-col
                            cols="12"
                            class="pb-6"
                            v-if="showEditProposedFeeSection"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <!-- <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 104px; bottom: -18px;"
                                >
                                    Cancel
                                </v-btn> -->
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canProposeFee"
                                    @click="upsertServiceRequestBudget"
                                    :disabled="!validServiceRequestBudgetForm"
                                >
                                    Propose
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-form
                                        v-model="validServiceRequestBudgetForm"
                                        ref="serviceRequestBudgetForm"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                class="os-15-sb"
                                            >
                                                Project Fee Proposal
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="os-13-r py-0"
                                            >
                                                <general-tip-tap-text-editor-component
                                                    class="pb-6"
                                                    @textChanged="costDescriptionChanged"
                                                    :placeholder="'Add description and terms here.'"
                                                    :text="serviceRequest.cost_description"
                                                />
                                            </v-col>
                                            <v-col
                                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                                class="pb-0 os-15-sb"
                                            >
                                                Currency<span style="color: red;">*</span>
                                                <br />
                                                <v-select
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    item-color="#2E567A"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    v-model="serviceRequest.currency"
                                                    :items="currencies"
                                                    :rules="selectRules"
                                                    @change="serviceRequestBudgetEdited = true"
                                                    :readonly="currencies.length === 1 && serviceRequest.currency.id !== null"
                                                />
                                            </v-col>
                                            <v-col
                                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                                class="os-15-sb"
                                                :class="$store.state.general.customBreakpoints.xxs ? 'py-0' : 'pb-0'"
                                            >
                                                Project Fee<span style="color: red;">*</span>
                                                <br />
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="serviceRequest.cost"
                                                    :rules="costRules"
                                                    :prefix="serviceRequest.currency.symbol"
                                                    type="number"
                                                    @input="serviceRequestBudgetEdited = true"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Editable Proposed Project Fee -->

                        <!-- Request Edit Mode -->
                        <v-col
                            cols="12"
                            v-if="editMode"
                        >
                            <v-form
                                v-model="validServiceRequestForm"
                                ref="serviceRequestForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="os-15-sb pb-0"
                                    >
                                        Service Requirements
                                    </v-col>

                                    <!-- Requirements -->
                                    <v-col>
                                        <general-tip-tap-text-editor-component
                                            class="pb-3"
                                            :placeholder="'Provide some information about the service that you require. Be as descriptive as possible.'"
                                            @textChanged="descriptionChanged"
                                            :text="serviceRequest.requirements"
                                        />
                                    </v-col>
                                    <!-- Requirements -->

                                    <!-- Documents -->
                                    <v-col
                                        cols="12"
                                    >
                                        <general-document-component
                                            :documents="serviceRequest.document"
                                            :documentCheckboxes="false"
                                            :canDeleteDocuments="true"
                                            :canDownloadDocuments="false"
                                            :canEditDocuments="true"
                                            :fileType="'document'"
                                            @deleteDocument="confirmDeleteDocument"
                                            @createDocument="createDocument"
                                        />
                                    </v-col>
                                    <!-- Documents -->

                                    <!-- Date Requirements -->
                                    <v-col
                                        class="os-15-sb pb-0"
                                        cols="12"
                                    >
                                        Do you require this by a specific date?
                                    </v-col>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-row
                                            align="center"
                                        >
                                            <v-col
                                                cols="12"
                                            >
                                                <v-radio-group
                                                    row
                                                    v-model="serviceRequest.is_specific_date"
                                                    hide-details
                                                    style="margin-top: 0px;"
                                                    @change="specificDateChanged"
                                                >
                                                    <v-radio
                                                        label="Yes"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Yes"
                                                    />
                                                    <v-radio
                                                        label="No"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="No"
                                                    />
                                                    <v-radio
                                                        label="Unknown"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Unknown"
                                                    />
                                                </v-radio-group>
                                            </v-col>
                                            <v-col
                                                class="py-0"
                                                cols="12"
                                            >
                                                <v-menu
                                                    v-model="dateMenu"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            color="#2E567A"
                                                            class="pt-2 os-13-r"
                                                            background-color="white"
                                                            :rules="dateRules"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            v-model="serviceRequest.start_date"
                                                            :disabled="serviceRequest.is_specific_date !== 'Yes'"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        no-title
                                                        scrollable
                                                        color="#2E567A"
                                                        @input="dateMenu = false"
                                                        v-model="serviceRequest.start_date"
                                                    />
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- Date Requirements -->

                                    <!-- E-mail Notifications -->
                                    <!-- <v-col
                                        cols="12"
                                        class="os-12-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Your request will be sent to the fixer and they will be notified of via the messenger. Do you want to be <span class="os-12-sb">notified via email</span> when you receive a reply?
                                    </v-col> -->
                                    <v-col
                                        cols="12"
                                    >
                                        <v-row
                                            align="center"
                                        >
                                            <!-- <v-col
                                                cols="12"
                                            >
                                                <v-radio-group
                                                    row
                                                    v-model="serviceRequest.receive_email"
                                                    hide-details
                                                    style="margin-top: 0px;"
                                                >
                                                    <v-radio
                                                        label="Yes"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="Yes"
                                                    />
                                                    <v-radio
                                                        label="No"
                                                        color="#2E567A"
                                                        class="blue--text--xxs"
                                                        :ripple="false"
                                                        value="No"
                                                    />
                                                </v-radio-group>
                                            </v-col> -->
                                            <v-col
                                                class="text-right"
                                                cols="12"
                                            >
                                                <v-btn
                                                    text
                                                    class="no-uppercase rounded-lg os-13-sb mr-4"
                                                    color="#2E567A"
                                                    @click="editMode = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                                    color="#FFA858"
                                                    @click="upsertServiceRequest"
                                                    :disabled="cannotRequestService"
                                                >
                                                    Save
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                <!-- E-mail Notifications -->
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Request Edit Mode -->

                        <!-- Request Details -->
                        <v-col
                            cols="12"
                            v-else
                            class="pb-6"
                        >
                            <v-card
                                class="rounded-lg"
                                style="position: relative;"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="editMode = true"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canEditServiceRequest"
                                >
                                    Edit Service Request
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#E2574C"
                                    style="position: absolute; right: 12px; bottom: -18px;"
                                    v-if="canRejectServiceRequest"
                                    @click="confirmRejectServiceRequest"
                                >
                                    Reject
                                </v-btn>
                                <v-card-text
                                    style="color: black;"
                                >
                                    <v-row
                                        justify="center"
                                    >
                                        <v-col
                                            class="flex-shrink-1 flex-grow-0"
                                        >
                                            <v-avatar
                                                height="46px"
                                                width="46px"
                                            >
                                                <v-img
                                                    :src="otherParty.avatar ? otherParty.avatar : otherParty.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                                    contain
                                                />
                                            </v-avatar>
                                        </v-col>
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0"
                                        >
                                            <div
                                                class="os-15-sb text-truncate"
                                            >
                                                {{ serviceRequest.service.name }}
                                            </div>
                                            <div
                                                style="color: #2E567A; opacity: 0.65;"
                                                class="os-13-r"
                                            >
                                                <div @click="goToProfile()" style="cursor: pointer; text-decoration: underline;" class="text-truncate">
                                                    <span v-if="otherParty.company_id">{{ otherParty.company_name }}</span>
                                                    <span v-else>{{ otherParty.first_name }} {{ otherParty.last_name }}</span>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-15-sb"
                                        >
                                            Service Requirements
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="os-13-r pt-0"
                                            v-html="serviceRequest.requirements"
                                        >
                                        </v-col>
                                        <v-col
                                            cols="12"
                                        >
                                            <div
                                                class="os-15-sb pb-4"
                                            >
                                                <v-icon
                                                    color="black"
                                                >
                                                    {{ mdiCloudDownloadOutline }}
                                                </v-icon>
                                                <div style="max-width: calc(100% - 48px); display: inline;" class="ml-1 text-truncate">Download Document(s)</div>
                                            </div>
                                            <general-document-component
                                                :documents="serviceRequest.document"
                                                :documentCheckboxes="false"
                                                :canDeleteDocuments="false"
                                                :canEditDocuments="false"
                                                :fileType="'document'"
                                                @deleteDocument="confirmDeleteDocument"
                                                @downloadDocument="downloadDocument"
                                                @createDocument="createDocument"
                                                class="pt-3"
                                            />
                                        </v-col>
                                        <v-col
                                            :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                            :class="$store.state.general.customBreakpoints.xxs ? '' : 'pb-6'"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                Requested on:
                                            </div>
                                            <div
                                                style="color: #2E567A;"
                                                class="os-12-sb"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                                <span>{{ serviceRequest.created_at }}</span>
                                            </div>
                                        </v-col>
                                        <v-col
                                            :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                            class="pb-6"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                Required by:
                                            </div>
                                            <div
                                                style="color: #2E567A;"
                                                class="os-12-sb"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                >
                                                    {{ mdiCalendar }}
                                                </v-icon>
                                                <span v-if="serviceRequest.start_date">{{ serviceRequest.start_date }}</span>
                                                <span
                                                    class="os-12-r text-center"
                                                    style="color: rgba(0, 0, 0, 0.38);"
                                                    v-else
                                                >
                                                    No date
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- Request Details -->
                    </v-row>
                </v-col>
                <!-- Rating & Review, Project Fee and Request Details -->

                <!-- Messages -->
                <v-col
                    cols="12"
                    class="pb-9"
                >
                    <general-work-messaging-component
                        :selectedItem="serviceRequest"
                        :canSendMessages="canSendMessages"
                        :isService="true"
                        @resetUnreadMessageCount="resetUnreadMessageCount"
                    />
                </v-col>
                <!-- Messages -->
            </v-row>
        </v-col>
        <!-- Mobile -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->

        <!-- Confirm Accept Project Fee Overlay -->
        <work-professional-service-request-confirm-accept-fee-overlay-component
            :service="serviceRequest.service"
            :serviceRequest="serviceRequest"
            :showAcceptFeeOverlayBoolean="showAcceptFeeOverlayBoolean"
            @toggleAcceptFeeOverlayComponent="toggleAcceptFeeOverlayComponent"
        />
        <!-- Confirm Accept Project Fee Overlay -->

        <!-- Confirm Reject Project Fee Overlay -->
        <work-professional-service-request-confirm-reject-fee-overlay-component
            :service="serviceRequest.service"
            :serviceRequest="serviceRequest"
            :showRejectFeeOverlayBoolean="showRejectFeeOverlayBoolean"
            @toggleRejectFeeOverlayComponent="toggleRejectFeeOverlayComponent"
        />
        <!-- Confirm Reject Project Fee Overlay -->

        <!-- Confirm Reject Service Request Overlay -->
        <work-professional-service-request-confirm-reject-overlay-component
            :service="serviceRequest.service"
            :serviceRequest="serviceRequest"
            :showRejectServiceRequestOverlayBoolean="showRejectServiceRequestOverlayBoolean"
            @toggleRejectServiceRequestOverlayComponent="toggleRejectServiceRequestOverlayComponent"
        />
        <!-- Confirm Reject Service Request Overlay -->

        <!-- Confirm Complete Service Request Overlay -->
        <work-professional-service-request-confirm-complete-overlay-component
            :service="serviceRequest.service"
            :serviceRequest="serviceRequest"
            :showCompleteServiceRequestOverlayBoolean="showCompleteServiceRequestOverlayBoolean"
            @toggleCompleteServiceRequestOverlayComponent="toggleCompleteServiceRequestOverlayComponent"
        />
        <!-- Confirm Complete Service Request Overlay -->
    </v-row>
</template>
<script>
    import Vue from 'vue'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import GeneralWorkMessagingComponent from '@/components/general/work/GeneralWorkMessagingComponent.vue'
    import GeneralWorkRatingReviewComponent from '@/components/general/work/GeneralWorkRatingReviewComponent.vue'
    import { mdiArrowLeftThinCircleOutline, mdiMapMarkerOutline, mdiStarOutline, mdiHeart, mdiHeartOutline, mdiCloudDownloadOutline, mdiCalendar } from '@mdi/js'
    import { WorkProfessionalServiceRequestDocumentController, LookupLocationController, WorkProfessionalServiceRequestController, GeneralInvoiceController, WorkProfessionalServiceDetailController, WorkJobApplicationDetailController, WorkJobApplicationDocumentController, GeneralProfileDocumentController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'
    import WorkProfessionalServiceRequestConfirmRejectFeeOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmRejectFeeOverlayComponent.vue'
    import WorkProfessionalServiceRequestConfirmAcceptFeeOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmAcceptFeeOverlayComponent.vue'
    import WorkProfessionalServiceRequestConfirmRejectOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmRejectOverlayComponent.vue'
    import WorkProfessionalServiceRequestConfirmCompleteOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmCompleteOverlayComponent.vue'

    const moment = require('moment')

    export default {
        name: 'WorkProfessionalServiceActionGeneralRequestedPage',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralDocumentComponent,
            GeneralWorkMessagingComponent,
            GeneralConfirmDeleteOverlayComponent,
            PaymentOverlayComponent,
            WorkProfessionalServiceRequestConfirmRejectFeeOverlayComponent,
            WorkProfessionalServiceRequestConfirmAcceptFeeOverlayComponent,
            GeneralWorkRatingReviewComponent,
            WorkProfessionalServiceRequestConfirmRejectOverlayComponent,
            WorkProfessionalServiceRequestConfirmCompleteOverlayComponent
        },

        watch: {
            '$route.params.service_id': {
                async handler (value) {
                    await this.getProfessionalServiceRequest()
                }
            },

            '$route.params.request_id': {
                async handler (value) {
                    await this.getProfessionalServiceRequest()
                }
            },

            '$store.state.socketIO.initialized': {
                async handler (value) {
                    this.workProfessionalServiceRequestController = new WorkProfessionalServiceRequestController()

                    if (value) this.listenForBudgetUpdates()
                },
                immediate: true
            },

            editMode: {
                handler (value) {
                    if (value) {
                        if (this.serviceRequest.start_date) {
                            const dateTimeFormat = "YYYY-MM-DD"
                            this.serviceRequest.start_date = moment(this.serviceRequest.start_date).format(dateTimeFormat)
                        }

                        if (this.serviceRequest.receive_email === 1) this.serviceRequest.receive_email = 'Yes'
                        else if (this.serviceRequest.receive_email === 0) this.serviceRequest.receive_email = 'No'

                        if (this.serviceRequest.is_specific_date === 1) this.serviceRequest.is_specific_date = 'Yes'
                        else if (this.serviceRequest.is_specific_date === 0) this.serviceRequest.is_specific_date = 'No'
                        else if (this.serviceRequest.is_specific_date === null) this.serviceRequest.is_specific_date = 'Unknown'
                    }
                }
            }
        },

        computed: {
            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            costRules () {
                return [
                    v => !!v || 'Required field',
                    v => v > 0 || 'Amount > 0'
                ]
            },

            canEditDocuments () {
                // if (this.$store.state.user.userContext === 'individual' && this.jobToApplyFor.job_stage_id === 2) return true
                // else return false
                return true
            },

            documentCheckboxes () {
                // if (this.$store.state.user.userContext === 'individual' && this.jobToApplyFor.job_stage_id === 2) return true
                // else return false
                return false
            },

            dateRules () {
                let today = new Date()
                today = today.toISOString().split('T')[0]

                if (this.serviceRequest.is_specific_date === 'Yes') {
                    if (this.serviceRequest.start_date === null) return [ 'Date required' ]
                    else if (this.serviceRequest.start_date.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                    else if (new Date(this.serviceRequest.start_date.replace(/-/g, '/')).getTime() < new Date(today.replace(/-/g, '/')).getTime()) return [ 'Can\'t be before today' ]
                    else return []
                } else return []
            },

            canSendMessages () {
                if (this.serviceRequest.status.stage_id === 2 || this.serviceRequest.status.stage_id === 3) return true
                else return false
            },

            showReviewSection () {
                // In review process
                if (Number(this.serviceRequest.status.id) === 18 || Number(this.serviceRequest.status.id) === 21 || Number(this.serviceRequest.status.id) === 20) return true
                // else if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id &&
                //     (Number(this.serviceRequest.status.id) === 21)) return true
                // else if (this.serviceRequest.owner.id === this.$store.state.user.userData.id &&
                //     (Number(this.serviceRequest.status.id) === 20)) return true
                else return false
            },

            canEditServiceRequest () {
                if (this.serviceRequest.owner.id === this.$store.state.user.userData.id && (this.serviceRequest.status.stage_id === 2 || this.serviceRequest.status.stage_id === 3)) return true
                else return false
            },

            canRejectServiceRequest () {
                if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id && this.serviceRequest.status.stage_id === 2) return true
                else return false
            },

            otherParty () {
                if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id) return this.serviceRequest.owner
                else if (this.serviceRequest.owner.id === this.$store.state.user.userData.id) return this.serviceRequest.service.owner
                else return {}
            },

            showProposedFeeSection () {
                if (this.serviceRequest.owner.id === this.$store.state.user.userData.id /* && this.serviceRequest.cost */) return true
                else if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id && this.serviceRequest.status.stage_id > 2) return true
                else return false
            },

            showEditProposedFeeSection () {
                if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id && this.serviceRequest.status.stage_id === 2) return true
                else return false
            },

            cannotRequestService () {
                if (this.validServiceRequestForm && this.validDescription) return false
                else return true
            },

            canCompleteAndRequestPayment () {
                if (this.serviceRequest.service.owner.id === this.$store.state.user.userData.id &&
                    ((Number(this.serviceRequest.status.id) === 3) ||
                        (Number(this.serviceRequest.status.id) === 14))) return true
                else return false
            },

            canCompleteAndApprovePayment () {
                if (this.serviceRequest.owner.id === this.$store.state.user.userData.id && 
                    ((Number(this.serviceRequest.status.id) === 3) ||
                        (Number(this.serviceRequest.status.id) === 15))) return true
                else return false
            },

            canAcceptRejectFee () {
                if (this.serviceRequest.owner.id === this.$store.state.user.userData.id && this.serviceRequest.status.stage_id === 2 && this.serviceRequest.cost) return true
                else return false
            },

            canProposeFee () {
                if ((this.serviceRequestBudgetEdited || this.serviceRequestBudgetIsServiceCost) && this.serviceRequest.service.owner.id === this.$store.state.user.userData.id && this.serviceRequest.status.stage_id === 2) return true
                else return false
            }
        },

        data () {
            return {
                mdiArrowLeftThinCircleOutline,
                mdiMapMarkerOutline,
                mdiStarOutline,
                mdiHeart,
                mdiHeartOutline,
                mdiCloudDownloadOutline,
                mdiCalendar,
                jobToApplyFor: {},
                workJobApplicationDetailController: null,
                workJobApplicationDocumentController: null,
                generalProfileDocumentController: null,
                generalInvoiceController: null,
                showApplyOverlayBoolean: false,
                applicationDocuments: [],
                showDeleteConfirmationOverlayBoolean: false,
                documentToDelete: null,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false,
                isFavourite: false,
                dateRadio: 'Yes',
                emailRadio: 'Yes',
                paymentTypeRadio: 'Payment Via Fixer',
                dateMenu: false,
                date: null,
                showRejectFeeOverlayBoolean: false,
                showAcceptFeeOverlayBoolean: false,
                editMode: false,
                workProfessionalServiceRequestController: null,
                workProfessionalServiceRequestDocumentController: null,
                workProfessionalServiceDetailController: null,
                serviceRequest: {
                    owner: {},
                    status: {},
                    currency: {},
                    payment_option: {
                        name: ''
                    },
                    service: {
                        owner: {}
                    }
                },
                validServiceRequestForm: false,
                validDescription: false,
                validServiceRequestBudgetForm: false,
                currencies: [],
                showRejectServiceRequestOverlayBoolean: false,
                showCompleteServiceRequestOverlayBoolean: false,
                serviceRequestBudgetEdited: false,
                serviceRequestBudgetIsServiceCost: false
            }
        },

        beforeMount () {
            this.workJobApplicationDetailController = new WorkJobApplicationDetailController()
            this.workJobApplicationDocumentController = new WorkJobApplicationDocumentController()
            this.generalProfileDocumentController = new GeneralProfileDocumentController()
            this.generalInvoiceController = new GeneralInvoiceController()
            // this.workProfessionalServiceRequestController = new WorkProfessionalServiceRequestController()
            this.workProfessionalServiceDetailController = new WorkProfessionalServiceDetailController()
            this.lookupLocationController = new LookupLocationController()
            this.workProfessionalServiceRequestDocumentController = new WorkProfessionalServiceRequestDocumentController()
        },

        async mounted () {
            this.getCurrencies()
            await this.getProfessionalServiceRequest()
            // await this.getApplicationDocuments()

            if (this.$store.state.socketIO.initialized) this.listenForBudgetUpdates()
        },

        methods: {
            back () {
                let stage
                if (this.serviceRequest.status.stage_id === 2) stage = 'pending'
                else if (this.serviceRequest.status.stage_id === 3) stage = 'active'
                else if (this.serviceRequest.status.stage_id === 4) stage = 'history'

                if (this.$store.state.user.userData.id === this.serviceRequest.owner.id) this.$router.push(`/service/requested/${stage}`)
                else if (this.$store.state.user.userData.id === this.serviceRequest.service.owner.id) this.$router.push(`/service/provided/${stage}`)
            },

            costDescriptionChanged (text) {
                if (text !== this.serviceRequest.cost_description) this.serviceRequestBudgetEdited = true
                this.serviceRequest.cost_description = text

                if (this.serviceRequest.cost_descriptio === '<p></p>') {
                    this.serviceRequest.cost_descriptio = null
                }
            },

            goToProfile () {
                if (this.otherParty.role_id === 1) this.$router.push(`/profile/individual/${this.otherParty.id}`)
                else if (this.otherParty.role_id === 2) this.$router.push(`/profile/company/${this.otherParty.company_id}`)
            },

            async listenForBudgetUpdates () {
                const userID = this.$store.state.user.userData.id

                Vue.prototype.$socketIO.on(`user/${userID}/notification`, async (notification) => {
                    notification = notification.message

                    if (notification.service_id) {
                        if (notification.service_id === Number(this.$route.params.service_id) &&
                            notification.work_id === Number(this.$route.params.request_id) &&
                            notification.notification_context_id === 1 &&
                            notification.notification_type_id === 2 &&
                            (notification.notification_event_id === 5) || (notification.notification_event_id === 3)) {
                            await this.updateProfessionalServiceRequestBudget()
                        }
                    }
                })
            },

            async submitReview (rating, review) {
                try {
                    const reviewToSubmit = {
                        rating,
                        review
                    }

                    await this.workProfessionalServiceRequestController.review(this.$route.params.service_id, this.$route.params.request_id, reviewToSubmit)

                    await this.getProfessionalServiceRequest()

                    if (this.serviceRequest.status.id === 7) {
                        this.$store.commit('setSnackbar', {
                            text: 'Service completed',
                            show: true,
                            color: '#069B34'
                        })

                        if (this.serviceRequest.owner.id === this.$store.state.user.userData.id) this.$router.push('/service/requested/history')
                        else this.$router.push('/service/provided/history')
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Review submitted successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCurrencies () {
                try {
                    this.currencies = await this.lookupLocationController.getCurrencies()

                    if (this.currencies.length === 1) this.serviceRequest.currency = this.currencies[0]
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfessionalServiceRequest () {
                try {
                    this.serviceRequest = await this.workProfessionalServiceRequestController.retrieve(this.$route.params.service_id, this.$route.params.request_id)
                    this.service = this.serviceRequest.service

                    this.serviceRequest.applicant_channel_id = this.serviceRequest.twillio_channel_id

                    this.serviceRequest.document = await this.workProfessionalServiceRequestDocumentController.retrieve(this.$route.params.service_id, this.$route.params.request_id)

                    if (this.serviceRequest.created_at) {
                        const removeTime = this.serviceRequest.created_at.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.serviceRequest.created_at = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }

                    // if (this.serviceRequest.is_specific_date === null) this.serviceRequest.is_specific_date = 'Unknown'
                    // else if (this.serviceRequest.is_specific_date === 0) this.serviceRequest.is_specific_date = 'No'
                    // else if (this.serviceRequest.is_specific_date === 1) this.serviceRequest.is_specific_date = 'Yes'

                    if (this.serviceRequest.is_specific_date === 1) {
                        const removeTime = this.serviceRequest.start_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.serviceRequest.start_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }

                    if (!this.serviceRequest.cost && (this.$store.state.user.userData.id === this.serviceRequest.service.owner.id)) {
                        this.serviceRequest.cost = this.serviceRequest.service.rate
                        this.serviceRequest.currency = this.serviceRequest.service.currency
                        this.serviceRequestBudgetIsServiceCost = true
                    }
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmView, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmView) this.payInvoice(this.invoiceData, selectedCard)
                else if (!confirmView && !value) this.$router.push('/service/provided/pending')
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.getProfessionalServiceRequest()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async updateProfessionalServiceRequestBudget () {
                try {
                    const serviceRequest = await this.workProfessionalServiceRequestController.retrieve(this.$route.params.service_id, this.$route.params.request_id)

                    this.serviceRequest.cost = serviceRequest.cost
                    this.serviceRequest.cost_description = serviceRequest.cost_description
                    this.serviceRequest.currency = serviceRequest.currency
                } 
                catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertServiceRequest () {
                try {
                    if (this.serviceRequest.is_specific_date === 'Yes') this.serviceRequest.is_specific_date = 1
                    else if (this.serviceRequest.is_specific_date === 'No') this.serviceRequest.is_specific_date = 0
                    else if (this.serviceRequest.is_specific_date === 'Unknown') this.serviceRequest.is_specific_date = null

                    if (this.serviceRequest.receive_email === 'Yes') this.serviceRequest.receive_email = 1
                    else if (this.serviceRequest.receive_email === 'No') this.serviceRequest.receive_email = 0

                    await this.workProfessionalServiceRequestController.upsert(this.$route.params.service_id, this.serviceRequest)

                    // *TODO* UPLOAD DOCS

                    this.$store.commit('setSnackbar', {
                        text: 'Request updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.editMode = false
                    await this.getProfessionalServiceRequest()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertServiceRequestBudget () {
                try {
                    const serviceRequestBudget = {
                        cost: this.serviceRequest.cost,
                        cost_description: this.serviceRequest.cost_description,
                        currency: this.serviceRequest.currency,
                        payment_option: this.serviceRequest.payment_option
                    }
        
                    await this.workProfessionalServiceRequestController.upsertBudget(this.$route.params.service_id, this.$route.params.request_id, serviceRequestBudget)
                
                    this.$store.commit('setSnackbar', {
                        text: 'Fee proposal updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.serviceRequestBudgetEdited = false
                    this.serviceRequestBudgetIsServiceCost = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            specificDateChanged () {
                if (this.serviceRequest.is_specific_date !== 'Yes' && this.serviceRequest.is_specific_date !== 1) this.serviceRequest.start_date = null
            },

            async getApplicationDocuments() {
                try {
                    this.applicationDocuments = await this.workJobApplicationDocumentController.retrieve(this.jobToApplyFor.job_id, this.$store.state.user.userData.id) // await this.generalProfileDocumentController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmRejectFee () {
                this.toggleRejectFeeOverlayComponent(true)
            },

            toggleRejectFeeOverlayComponent (value, confirmReject) {
                this.showRejectFeeOverlayBoolean = value

                if (confirmReject) this.rejectFee(this.item)
            },

            async rejectFee () {
                try {
                    await this.workProfessionalServiceRequestController.rejectBudget(this.$route.params.service_id, this.$route.params.request_id)
                
                    this.$store.commit('setSnackbar', {
                        text: 'Fee proposal rejected successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmRejectServiceRequest () {
                this.toggleRejectServiceRequestOverlayComponent(true)
            },

            toggleRejectServiceRequestOverlayComponent (value, confirmReject) {
                this.showRejectServiceRequestOverlayBoolean = value

                if (confirmReject) this.rejectServiceRequest(this.item)
            },

            async rejectServiceRequest () {
                try {
                    await this.workProfessionalServiceRequestController.reject(this.$route.params.service_id, this.$route.params.request_id)
                
                    this.$store.commit('setSnackbar', {
                        text: 'Request rejected successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.$router.push('/service/provided/history')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmCompleteServiceRequest () {
                this.toggleCompleteServiceRequestOverlayComponent(true)
            },

            toggleCompleteServiceRequestOverlayComponent (value, confirmComplete) {
                this.showCompleteServiceRequestOverlayBoolean = value

                if (confirmComplete) this.completeServiceRequest(this.item)
            },

            async completeServiceRequest () {
                try {
                    await this.workProfessionalServiceRequestController.complete(this.$route.params.service_id, this.$route.params.request_id)
                
                    this.$store.commit('setSnackbar', {
                        text: 'Request completed successfully',
                        show: true,
                        color: '#069B34'
                    })

                    await this.getProfessionalServiceRequest()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmAcceptFee () {
                this.toggleAcceptFeeOverlayComponent(true)
            },

            toggleAcceptFeeOverlayComponent (value, confirmAccept) {
                this.showAcceptFeeOverlayBoolean = value

                if (confirmAccept) this.acceptFee(this.item)
            },

            async acceptFee () {
                try {
                    await this.workProfessionalServiceRequestController.acceptBudget(this.$route.params.service_id, this.$route.params.request_id)

                    this.$store.commit('setSnackbar', {
                        text: 'Fee proposal accepted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    await this.getProfessionalServiceRequest()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            resetUnreadMessageCount () {
                try {
                    this.workProfessionalServiceRequestController.resetMessageCount(this.serviceRequest.service.id, this.serviceRequest.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            descriptionChanged (text) {
                this.serviceRequest.requirements = text
                if (this.serviceRequest.requirements === '<p></p>' || this.serviceRequest.requirements === null) {
                    this.serviceRequest.requirements = null
                    this.validDescription = false
                } else this.validDescription = true
            },

            dateRequirementChanged () {

            },

            async createDocument (document) {
                try {
                    this.serviceRequest.document = await this.workProfessionalServiceRequestDocumentController.create(this.$route.params.service_id, this.$route.params.request_id, document)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteDocument (document) {
                this.documentToDelete = document
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument (document) {
                try {
                    this.serviceRequest.document = await this.workProfessionalServiceRequestDocumentController.delete(this.$route.params.service_id, this.$route.params.request_id, document)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.documentToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument (documentToDownload) {
                try {
                    const downloadResponse = await this.workProfessionalServiceRequestDocumentController.download(this.$route.params.service_id, this.$route.params.request_id, encodeURIComponent(documentToDownload.fullpath))

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete)
            }
        }
    }
</script>
<style scoped>
    .blue--text--xxs /deep/ label {
        color: black;
        font-size: 12px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }

    .v-textarea >>> div.v-input__slot.white {
        min-height: 0 !important;
        height: 72px !important;
    }
</style>
