<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-21 09:44:59
 * @ Description: Overlay to confirm requesting of a service.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showAcceptFeeOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="530px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="205px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Service Details -->
                    <v-col
                        cols="4"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <v-avatar
                                    size="64"
                                    style="right: -8px;"
                                >
                                    <v-img
                                        :src="service.owner.avatar ? service.owner.avatar : service.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                                <v-avatar
                                    size="64"
                                    style="left: -8px;"
                                >
                                    <v-img
                                        :src="serviceRequest.owner.avatar ? serviceRequest.owner.avatar : serviceRequest.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center two-lines"
                                style="max-height: 60px;"
                            >
                                {{ service.name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-20-b text-center pt-1"
                                style="color: #2E567A;"
                            >
                                {{ serviceRequest.currency.symbol }}{{ serviceRequest.cost }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Service Details -->

                    <v-col
                        cols="8"
                        style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25); display: flex; align-items: center;"
                    >
                        <v-row
                            class="px-9 py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                <div class="os-18-sb">Accept Proposed Project Fee</div>
                                <div class="os-16-r">Payment Method: {{ serviceRequest.payment_option.name.toUpperCase() }}</div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center pb-0 os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleAcceptFeeOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleAcceptFeeOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showAcceptFeeOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Service Details -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <v-avatar
                                    size="64"
                                    style="right: -8px;"
                                >
                                    <v-img
                                        :src="service.owner.avatar ? service.owner.avatar : service.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                                <v-avatar
                                    size="64"
                                    style="left: -8px;"
                                >
                                    <v-img
                                        :src="serviceRequest.owner.avatar ? serviceRequest.owner.avatar : serviceRequest.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center two-lines"
                                style="max-height: 60px;"
                            >
                                {{ service.name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-20-b text-center pt-1"
                                style="color: #2E567A;"
                            >
                                {{ serviceRequest.currency.symbol }}{{ serviceRequest.cost }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Service Details -->

                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                <div class="os-18-sb">Accept Proposed Project Fee</div>
                                <div class="os-16-r">Payment Method: {{ serviceRequest.payment_option.name.toUpperCase() }}</div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleAcceptFeeOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleAcceptFeeOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    export default {
        name: 'WorkProfessionalServiceRequestConfirmAcceptFeeOverlayComponent',

        props: {
            showAcceptFeeOverlayBoolean: {
                type: Boolean,
                required: true
            },

            service: {
                type: Object,
                required: true
            },

            serviceRequest: {
                type: Object,
                required: true
            }
        },

        methods: {
            toggleAcceptFeeOverlayComponent (confirmAccept) {
                this.$emit('toggleAcceptFeeOverlayComponent', false, confirmAccept)
            }
        }
    }
</script>
<style scoped>
    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>
